import { init } from '@elastic/apm-rum';

let environment = window.process.env.APP_ENV || 'local';
environment = environment === '##APP_ENV##' ? 'local' : environment;

export const logging = init({
  serviceName: 'app-interhyp-home',
  serviceVersion: process.env.APP_VERSION,
  serverUrl: '/api/apm',
  serverUrlPrefix: '/whisky/',
  logLevel: 'info',
  active: environment !== 'ci' && environment !== 'local',
  environment,
});
