import { setServiceResolver } from '@interhyp/platform-request';
import { matchPath } from '@interhyp/platform-router';
import './util/logging';

setServiceResolver(serviceName => {
  const { pathname } = window.location;
  const match = [
    matchPath(pathname, { path: '/:intentId/vorhaben-neu' }),
    matchPath(pathname, { path: '/:intentId/unterlagen' }),
    matchPath(pathname, { path: '/:intentId/voranfrage', exact: true }),
    matchPath(pathname, { path: '/:intentId/voranfrage/:preInquiryId', exact: true }),
    matchPath(pathname, { path: '/:intentId/voranfrage/:preInquiryId/documents' }),
  ].find(m => m);

  if (match) {
    const { intentId } = match.params;
    return `/${intentId}/api/${serviceName}`;
  }

  return `/api/${serviceName}`;
});

import('./app');
